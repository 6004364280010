<template>
  <div class="user-security-setting">
    <van-nav-bar
      title="安全设置"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <van-cell-group>
      <div class="user-security-box">
        <van-cell v-if="this.inXbiApp && this.deviceType === 'ios'" title="账户密码" is-link >
          <div slot="label">
            <span>当前密码强度：</span><span>强</span>
          </div>
        </van-cell>
        <van-cell v-else title="账户密码" is-link :to="{ name: 'ChangePassword'}" >
          <div slot="label">
            <span>当前密码强度：</span><span>强</span>
          </div>
        </van-cell>
      </div>
      <div class="user-security-box">
        <van-cell v-if="this.inXbiApp && this.deviceType === 'ios'" title="密保手机" is-link >
          <div slot="label">
            <!-- <div>未绑定密保手机</div> -->
            <span>已绑定手机：</span><span>{{ this.$store.state.user.profile.mobile ? this.$store.state.user.profile.mobile : '无' }}</span>
          </div>
        </van-cell>
        <van-cell v-else title="密保手机" is-link :to="{ name: 'ChangeSecretPhone'}" >
          <div slot="label">
            <!-- <div>未绑定密保手机</div> -->
            <span>已绑定手机：</span><span>{{ this.$store.state.user.profile.mobile ? this.$store.state.user.profile.mobile : '无' }}</span>
          </div>
        </van-cell>
      </div>
      <div class="user-security-box">
        <van-cell v-if="this.inXbiApp && this.deviceType === 'ios'" title="备用邮箱" is-link >
          <div slot="label">
            <!-- <div>未绑定备用邮箱</div> -->
            <span>已绑定邮箱：</span><span>{{ this.$store.state.user.profile.email ? this.$store.state.user.profile.email : '无' }}</span>
          </div>
        </van-cell>
        <van-cell v-else title="备用邮箱" is-link :to="{ name: 'ChangeEmail'}" >
          <div slot="label">
            <!-- <div>未绑定备用邮箱</div> -->
            <span>已绑定邮箱：</span><span>{{ this.$store.state.user.profile.email ? this.$store.state.user.profile.email : '无' }}</span>
          </div>
        </van-cell>
      </div>
      <!-- <van-cell title="MFA 设备" is-link>
        <div slot="label">
          <div>未绑定 MFA 设备，绑定后，可以进行二次确认</div>
        </div>
      </van-cell> -->
      <!-- <van-form @submit="onSubmit">
        <van-field label="账号" value="输入框只读" readonly />
        <van-field
          v-model="nickname"
          name="昵称"
          label="昵称"
          placeholder="给自己起个名字"
          :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">保存</van-button>
        </div>
      </van-form> -->

    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'SecuritySetting',
  components: {},
  data () {
    return {
      nickname: '',
      imgFileList: []
    }
  },
  computed: {
    ...mapState({
      userProfile: state => state.user.profile,
      statusBarHeight: state => state.global.statusBarHeight,
      deviceType: state => state.global.deviceType,
      inXbiApp: state => state.global.inXbiApp
    })
  },
  methods: {
    getUserProfile () {
      this.$api.user.getUserProfile().then(res => {
        this.$store.commit('user/setProfile', res)
        console.log(res)
      }).catch((e) => {})
    },
    onClickLeft () {
      // this.$router.push({ name: 'User' })
      window.history.go(-1)
    },
    onSubmit () {

    },
    afterImgRead (file) {
      console.log(file)
      console.log(this.imgFileList)
      if (this.imgFileList.length > 1) {
        this.imgFileList.splice(0, 1)
      }
      console.log(this.imgFileList)
    }
  },
  mounted () {
    this.getUserProfile()
    console.log('this.$store.state.user', this.$store.state)
  }
}
</script>
<style lang="less">
.user-security-setting {
  background-color: #f8f8f8;
  height: 100vh;
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  .van-nav-bar {
    background-color: transparent;
    &::after {
      border-bottom-width: 0
    }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .van-cell-group {
    background-color: transparent;
    overflow: auto;
    padding-top: 15px;
    .user-security-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #878787;
            line-height: 28px;
          }
        }
        .dashboard-cell-icon {
          .van-icon {
            width: 20px;
            height: 20px;
            background-color: #37acdf14;
            border-radius: 50%;
            text-align: center;

            &::before {
              color: #319DE2;
              vertical-align: middle;
            }
          }
        }
      }
    }
    &::after {
      border-top-width: 0
    }
  }
}
</style>
